import React from "react";
import { useFormikContext } from "formik";
import {
  Section,
  SectionTitle,
  WrapRow,
  SmallSectionTitle
} from "../../../../shared/shared.styled";
import TextInput from "../../../../shared/components/text-input.component";
import { SaleRequest } from "../../../sales.interfaces";
import { SharedSectionProps } from "../../../../shared/shared.interface";

export const ClientSection = ({ isEditing }: SharedSectionProps) => {
  const { values, handleChange, errors } = useFormikContext<SaleRequest>();
  return (
    <Section>
      <SectionTitle>Cliente</SectionTitle>
      <SmallSectionTitle>Dados pessoais</SmallSectionTitle>
      <WrapRow>
        <TextInput
          inEdition={isEditing}
          value={values.client.name}
          required={true}
          title="Nome"
          name="client.name"
          error={errors.client && errors.client.name}
          onChange={handleChange}
          containerStyle={{ maxWidth: 250 }}
        />
        <TextInput
          inEdition={isEditing}
          value={values.client.birthDate}
          name="client.birthDate"
          onChange={handleChange}
          title="Data de nascimento"
          type="date"
          containerStyle={{ maxWidth: 250 }}
        />
        <TextInput
          inEdition={isEditing}
          value={values.client.civilState}
          name="client.civilState"
          onChange={handleChange}
          title="Estado civil"
          containerStyle={{ maxWidth: 250 }}
        />
        <TextInput
          inEdition={isEditing}
          value={values.client.citizenshipNumber}
          name="client.citizenshipNumber"
          onChange={handleChange}
          title="Nº cartão de cidadão"
          containerStyle={{ maxWidth: 250 }}
        />
        <TextInput
          inEdition={isEditing}
          value={values.client.expireCcDate}
          name="client.expireCcDate"
          onChange={handleChange}
          title="Data de validade do CC"
          type="date"
          containerStyle={{ maxWidth: 250 }}
        />
        <TextInput
          inEdition={isEditing}
          value={values.client.financeNumber}
          name="client.financeNumber"
          onChange={handleChange}
          title="Nº contribuinte"
          containerStyle={{ maxWidth: 250 }}
        />
      </WrapRow>
      <SmallSectionTitle>Morada</SmallSectionTitle>
      <WrapRow>
        <TextInput
          inEdition={isEditing}
          value={values.client.street}
          name="client.street"
          onChange={handleChange}
          title="Rua"
          containerStyle={{ maxWidth: 250 }}
        />
        <TextInput
          inEdition={isEditing}
          value={values.client.parish}
          name="client.parish"
          onChange={handleChange}
          title="Freguesia"
          containerStyle={{ maxWidth: 250 }}
        />
        <TextInput
          inEdition={isEditing}
          value={values.client.county}
          name="client.county"
          onChange={handleChange}
          title="Concelho"
          containerStyle={{ maxWidth: 250 }}
        />
        <TextInput
          inEdition={isEditing}
          name="client.district"
          onChange={handleChange}
          value={values.client.district}
          title="Distrito"
          containerStyle={{ maxWidth: 250 }}
        />
        <TextInput
          inEdition={isEditing}
          value={values.client.postalCode}
          name="client.postalCode"
          onChange={handleChange}
          title="Código postal"
          containerStyle={{ maxWidth: 250 }}
        />
      </WrapRow>
      <SmallSectionTitle>Contactos</SmallSectionTitle>
      <WrapRow>
        <TextInput
          inEdition={isEditing}
          value={values.client.mobileNumber}
          name="client.mobileNumber"
          onChange={handleChange}
          title="Nº telemóvel"
          containerStyle={{ maxWidth: 250 }}
        />
        <TextInput
          inEdition={isEditing}
          value={values.client.email}
          name="client.email"
          onChange={handleChange}
          title="Email"
          containerStyle={{ maxWidth: 250 }}
        />
      </WrapRow>
      <SmallSectionTitle>Outros</SmallSectionTitle>
      <WrapRow>
        <TextInput
          inEdition={isEditing}
          value={values.client.activity}
          name="client.activity"
          onChange={handleChange}
          title="Actividade"
          containerStyle={{ maxWidth: 250 }}
        />
        <TextInput
          inEdition={isEditing}
          value={values.client.observations}
          name="client.observations"
          onChange={handleChange}
          title="Observações"
          containerStyle={{ maxWidth: 250 }}
        />
      </WrapRow>
    </Section>
  );
};
