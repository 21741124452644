import React from "react";
import { NotificationTypeEnum } from "./shared.enums";
import {
  CustomNotification,
  Vehicle,
  LoggedUser,
  Company,
  Financial
} from "./shared.models";
import { Colors, Typography } from "../configs/styled.config";
import styled from "styled-components";
import { Column, RatioContainer, Row } from "./shared.styled";
import VehicleIcon from "../assets/icons/vehicle.icon";
import {
  Cost,
  CreateCompanyRequest,
  CreateVehicleRequest
} from "../admin/admin.interfaces";
import CompanyIcon from "../assets/icons/company.icon";

export const getNotificationDescription = (notif: CustomNotification) => {
  const { payload } = notif;
  switch (notif.notificationType) {
    case NotificationTypeEnum.IN_STOCK_VEHICLE:
      return "TODO";
    case NotificationTypeEnum.NEW_VEHICLE:
      return `O carro ${payload.brand} ${payload.model} (${payload.license_plate}) foi adicionado à plataforma.`;
    case NotificationTypeEnum.SOLD_VEHICLE:
      return `O carro ${payload.brand} ${payload.model} (${payload.license_plate}) foi vendido.`;
    case NotificationTypeEnum.UPDATED_VEHICLE:
      if (payload) {
        return `O carro ${payload.brand} ${payload.model} (${payload.license_plate}) foi alterado. Confirma as alterações`;
      } else {
        return `Um carro foi alterado`;
      }
  }
};

export const getLoanCompany = (financial: Financial) => {
  if (financial) return `${financial.name}`;
  return "";
};

export const getUserFullName = (user: LoggedUser | undefined) => {
  if (user) {
    return `${user.firstName ? user.firstName : user.username} ${
      user.lastName ? user.lastName : ""
    }`;
  }
  return "";
};

export const euroFormatter = new Intl.NumberFormat("pt-PT", {
  style: "currency",
  currency: "EUR"
});

export const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const getBrandAndModelComponent = (
  vehicle: Vehicle
): React.ReactNode => {
  return (
    <Column>
      <BrandText>{`${vehicle.brand} ${vehicle.model} ${vehicle.version ??
        ""}`}</BrandText>
      <LicensePlateText>{vehicle.licensePlate}</LicensePlateText>
    </Column>
  );
};

export const renderVehicleImageComponent = (vehicle: Vehicle) => {
  //const featuredPhoto = vehicle.photos.find(p => p.isFeatured)
  const featuredPhoto = vehicle.photo ? vehicle.photo : null;

  return (
    <RatioContainer
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
      ratio={1 / 10}
    >
      {featuredPhoto ? (
        <img
          style={{ width: 40, height: 40 }}
          src={featuredPhoto}
          alt="vehicle"
        />
      ) : (
        <div style={{ width: 40, height: 40 }}>
          <VehicleIcon />
        </div>
      )}
    </RatioContainer>
  );
};

export const getFormatedComponentFromDays = (days: number): React.ReactNode => {
  days = +days;
  let str = "";
  if (Number.isInteger(+days)) {
    const years = Math.floor(days / 365);
    if (years > 0) {
      const remainingDays = days % 365;
      if (remainingDays > 30) {
        const months = Math.floor(remainingDays / 30);
        str += `${years === 1 ? "1 ano" : `${years} anos`}`;
        if (months > 0) {
          str += ` e ${months === 1 ? "1 mês" : `${months} meses`}`;
        }
      } else {
        str += `${years === 1 ? "1 ano" : `${years} anos`}`;
      }
      return <span style={{ color: Colors["alive-red"] }}>{str}</span>;
    } else {
      const months = Math.floor(days / 30);
      if (months > 0) {
        str = months === 1 ? "1 mês" : `${months} meses`;
      } else {
        str = days === 0 ? "Hoje" : days === 1 ? `${days} dia` : `${days} dias`;
      }

      if (months >= 6) {
        return <span style={{ color: "#db8835" }}>{str}</span>;
      } else if (months >= 3) {
        return <span style={{ color: "green" }}>{str}</span>;
      } else {
        return str;
      }
    }
  } else {
    throw Error("Not a number");
  }
};

export const getCostsTotalValue = (costs: Cost[] | number): number => {
  if (typeof costs === "number") {
    return costs;
  } else if (costs && costs.length > 0) {
    return costs
      .filter((c) => c.costType !== "COMPLAINT")
      .map((c) => c.value)
      .reduce((prev, next) => prev + next, 0);
  } else {
    return 0;
  }
};

export const getVehicleTotalValue = (
  vehicle: Vehicle | CreateVehicleRequest
) => {
  const auxBuyerPrice = Number(vehicle.buyPrice) || 0;
  const totalCosts = vehicle.totalCosts
    ? vehicle.totalCosts
    : vehicle.costs
    ? vehicle.costs
        .filter((c) => c.costType !== "COMPLAINT")
        .map((c) => c.value)
        .reduce((prev, next) => prev + next, 0)
    : 0;

  return auxBuyerPrice + totalCosts;
};

export const getSaleMargin = (vehicle: Vehicle) => {
  const totalCostMargin =
    vehicle.buyPrice +
    (vehicle.totalCostsMargin ? vehicle.totalCostsMargin : 0);
  const salePriceNum = Number(vehicle.salePrice);
  return salePriceNum - totalCostMargin;
};

export const renderCompanyLogoComponent = (company: Company) => {
  const logoPhoto = company.logo ? company.logo : "";

  return (
    <RatioContainer
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
      ratio={1 / 10}
    >
      {logoPhoto ? (
        <img style={{ width: 40, height: 40 }} src={logoPhoto} alt="logo" />
      ) : (
        <div style={{ width: 40, height: 40 }}>
          <CompanyIcon />
        </div>
      )}
    </RatioContainer>
  );
};

export const getCompanyNameComponent = (
  company: Company | CreateCompanyRequest
) => {
  if (company) {
    return company.name;
  }
  return "----";
};

export const getCompanyColorComponent = (company: Company): React.ReactNode => (
  <Row>
    <ColorBox color={company.color ? company.color : ""}></ColorBox>
    {company.color ? company.color : "Sem cor definida"}
  </Row>
);

export const getCompanyStandsComponent = (company: Company) => {
  if (company.stands && company.stands.length > 0)
    return <BigNumbers>{company.stands?.length}</BigNumbers>;
  else return <BigNumbers>0</BigNumbers>;
};

export const getCompanyCarsComponent = (company: Company) => {
  if (company.stands && company.stands.length > 0)
    return (
      <BigNumbers>
        {company.vehiclesLength}
        {/* {company.stands
          .map((c) => c.vehiclesLength)
          .reduce((prev, next) => prev + next)} */}
      </BigNumbers>
    );
  else return <BigNumbers>0</BigNumbers>;
};

const BrandText = styled("span")`
  font-family: Merriweather-Light;
  font-size: 12px;
  font-weight: bold;
  color: #44566c;
`;

const LicensePlateText = styled("span")`
  ${Typography["Body_Text_#2_Low_Contrast_Left"]}
`;

const ColorBox = styled("div")<{ color: string }>`
  ${(props) =>
    props.color !== ""
      ? `
    background-color:${props.color};
    width: 80px;
    height: 30px;
    margin-right:8px;
    `
      : null}
`;

const BigNumbers = styled("span")`
  font-size: 24px !important;
  text-align: center;
`;
