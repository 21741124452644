import React from "react";
import { PageTitle } from "../../shared/shared.styled";
import { useDispatch, useSelector } from "react-redux";
import { IStoreInterface } from "../../configs/store.config";
import {
  AvailabilityEnum,
  VisibilityStatusEnum
} from "../../shared/shared.enums";
import WaitingVehicleSearch from "../components/waiting-vehicle-search.component";
import { useFilter } from "../../shared/hooks/use-filter.hook";
import { AdminUtils } from "../admin.utils";
import { LoggedUser, Vehicle } from "../../shared/shared.models";
import {
  Table,
  ColumnProps
} from "../../shared/components/table/table.component";
import {
  getFormatedComponentFromDays,
  euroFormatter,
  renderVehicleImageComponent,
  getBrandAndModelComponent
} from "../../shared/shared.utils";
import { SortOrderEnum, SortUtils } from "../../shared/sort.utils";
import { createSelector } from "reselect";
import { navigate } from "hookrouter";
import { RoutesEnum } from "../../routes.constants";
import TranslationUtils from "../../shared/utils/translations.utils";
import { useHasStandsOnly } from "../../shared/hooks/use-just-stand.hook";
import { useHasVehicles } from "../../shared/hooks/use-vehicles-exists.hook";
import { AdminApi } from "../admin.api";
import { AdminActions } from "../admin.store";
import { useAlert } from "react-alert";

const initialVehiclesSelector = createSelector(
  (state: IStoreInterface) => state.adminReducer.vehicles,
  (vehicles) =>
    vehicles.filter(
      (v) =>
        (v.sellerPrice < 1 && !v.sale) ||
        (v.sellerPrice < 1 &&
          v.sale &&
          v.sale.salePrice &&
          v.sale.salePrice < 1) ||
        v.availability === AvailabilityEnum.REPARING
    )
);

const VehicleAdminPage = () => {
  // Hooks initialization
  const { filters, updateFilter, clearAllFilters, clearFilter } = useFilter();
  const [isFetchingVehicles, setIsFetchingVehicles] = React.useState(false);
  const standExistOnly = useHasStandsOnly();
  const vehiclesExists = useHasVehicles();
  const dispatch = useDispatch();
  const loggedUser = useSelector(
    (state: IStoreInterface) => state.adminReducer.user as LoggedUser
  );

  const alert = useAlert();

  // Redux State
  const vehicles = useSelector(initialVehiclesSelector);
  // Local state
  const [visibleVehicles, setVisibleVehicles] = React.useState<Vehicle[]>([]);

  React.useEffect(() => {
    setVisibleVehicles(vehicles);
  }, [vehicles]);

  // Effects
  React.useEffect(() => {
    const queryParams = "?availability=VERIFYING,REPARING";
    setIsFetchingVehicles(true);
    AdminApi.methods
      .getVehiclesCompany(loggedUser.companyUuid, queryParams)
      .finally(() => setIsFetchingVehicles(false))
      .then(
        (res) => {
          const { vehicles } = res.data;
          dispatch(AdminActions.methods.getVehiclesSuccessAction(vehicles));
        },
        (e) => {
          alert.show(
            "Erro ao actualizar os veículos, por favor tente mais tarde",
            { type: "error" }
          );
        }
      );
  }, [dispatch, alert, loggedUser.companyUuid]);

  React.useEffect(() => {
    const newVisibleVehicles = AdminUtils.filterWaitingVehicles(
      filters,
      vehicles
    );
    setVisibleVehicles(newVisibleVehicles);
  }, [filters, vehicles]);

  const editVehicle = (vehicle: Vehicle) => {
    navigate(RoutesEnum.VEHICLE_PAGE.replace(":vehicleId", vehicle.uuid));
  };
  const getColumns = (): ColumnProps[] => {
    return [
      {
        key: "image",
        ratio: 1 / 10,
        label: "",
        renderFunction: (vehicle) => renderVehicleImageComponent(vehicle)
      },
      {
        key: "brand",
        ratio: 2 / 10,
        label: "Marca / Modelo / Versão",
        renderFunction: (vehicle) => getBrandAndModelComponent(vehicle),
        onClick: onHeaderClick
      },
      {
        key: "stock",
        label: "Tempo em stock",
        ratio: 1 / 10,
        renderFunction: (vehicle) => {
          const daysInStock = AdminUtils.getDaysInStock(vehicle.createdAt);
          return getFormatedComponentFromDays(daysInStock);
        },
        onClick: onHeaderClick,
        alignment: "center"
      },
      {
        key: "dateVehicle",
        label: "Data da Matrícula",
        ratio: 1.5 / 10,
        renderFunction: (vehicle) => AdminUtils.getVehicleMonthAndDate(vehicle),
        onClick: onHeaderClick,
        alignment: "center"
      },
      {
        key: "fuel",
        label: "Combustível",
        ratio: 1 / 10,
        renderFunction: (vehicle) =>
          TranslationUtils.getFuelTypeTranslation(vehicle.fuel)
      },
      {
        key: "availability",
        label: "Estado",
        ratio: 1 / 10,
        renderFunction: (vehicle) =>
          TranslationUtils.getAvailabilityTranslation(vehicle.availability)
      },
      {
        key: "buyPrice",
        label: "Preço de compra + custos",
        ratio: 1.5 / 10,
        visibility: VisibilityStatusEnum.ADMIN_ONLY,
        renderFunction: (vehicle: Vehicle) =>
          euroFormatter.format(
            vehicle.buyPrice + (vehicle.totalCosts ? vehicle.totalCosts : 0)
          ),
        onClick: onHeaderClick,
        alignment: "center"
      },
      {
        key: "sellerPrice",
        label: "Preço de tabela",
        ratio: 1 / 10,
        renderFunction: (vehicle) => euroFormatter.format(vehicle.sellerPrice),
        onClick: onHeaderClick,
        alignment: "flex-end"
      }
    ];
  };

  const onHeaderClick = (header: string, order: SortOrderEnum) => {
    const sortedVehicles = SortUtils.sortVehicles(
      visibleVehicles,
      header,
      order
    );
    setVisibleVehicles(sortedVehicles);
  };

  return (
    <>
      <PageTitle>Veículos em espera</PageTitle>
      <WaitingVehicleSearch
        clearAllFilters={clearAllFilters}
        clearFilter={clearFilter}
        updateFilter={updateFilter}
        filters={filters}
      />
      <Table<Vehicle>
        isLoadingData={isFetchingVehicles}
        columns={getColumns()}
        onRowClick={editVehicle}
        values={visibleVehicles}
        standExistOnly={standExistOnly}
        vehiclesExists={vehiclesExists}
      />
    </>
  );
};

export default VehicleAdminPage;
