import React from "react";
import {
  Column,
  Row,
  SectionTitle,
  RatioContainer,
  Section
} from "../../shared/shared.styled";
import { useFormikContext } from "formik";
import SmallButton from "../../shared/components/small-button.component";
import PlusIcon from "../../assets/icons/plus.icon";
import { Colors, Typography } from "../../configs/styled.config";
import { AddCostModal } from "./modals/add-cost.modal";
import { Cost, CreateVehicleRequest } from "../admin.interfaces";
import { useModal } from "react-modal-hook";
import { CostsTable } from "./costs-table.component";
import styled from "styled-components";
import { euroFormatter, getCostsTotalValue } from "../../shared/shared.utils";

export interface SelectedCost {
  cost: Cost;
  costIdx: number;
}

interface CostsSectionProps {
  isEditing?: boolean;
}

export const CostsSection = ({ isEditing = true }: CostsSectionProps) => {
  const formikProps = useFormikContext<CreateVehicleRequest>();

  const [selectedCost, setSelectedCost] = React.useState<
    SelectedCost | undefined
  >(undefined);

  const [showModal, hideModal] = useModal(
    () => (
      <AddCostModal
        selectedCost={selectedCost}
        hideModal={hideModal}
        addCost={addCost}
        editCost={editCost}
      />
    ),
    [selectedCost]
  );

  /**
   * Adds a cost to our formik values
   * @param values {Cost}
   */
  const addCost = (values: Cost) => {
    const previousCosts = formikProps.values.costs || [];
    previousCosts.push(values);

    formikProps.setFieldValue("costs", previousCosts);
    hideModal();
  };

  /**
   * Deletes a cost from our formik values
   * @param costIdx {number}
   */
  const deleteCost = (costIdx: number) => {
    const { costs, deletedCosts } = formikProps.values;
    let newDeletedCosts = deletedCosts;
    if (costs) {
      const deletedCost = costs.splice(costIdx, 1);
      // If we're in edition mode, we need to check if we need to persist this deletion on the backend
      // We only want to delete the costs that are already save in the backend
      if (deletedCost[0].uuid) {
        if (newDeletedCosts) {
          newDeletedCosts.push(deletedCost[0]);
        } else {
          newDeletedCosts = [];
          newDeletedCosts.push(deletedCost[0]);
        }
        formikProps.setFieldValue("deletedCosts", newDeletedCosts);
      }
      // We update our inmemory costs
      formikProps.setFieldValue("costs", costs);
    }
  };

  /**
   * Replaces a cost with the new values
   * @param obj SelectedCost
   */
  const editCost = (obj: SelectedCost) => {
    const previousCosts = formikProps.values.costs;
    if (previousCosts) {
      previousCosts.splice(obj.costIdx, 1, obj.cost);
      formikProps.setFieldValue("costs", previousCosts);
    }
    setSelectedCost(undefined);
    hideModal();
  };

  const showEditCostModal = (costIdx: number) => {
    const { costs } = formikProps.values;
    if (costs) {
      const cost = costs[costIdx];
      setSelectedCost({ cost, costIdx });
      showModal();
    }
  };

  return (
    <Section>
      <SectionTitle>
        <Row>
          Custos detalhados
          <Row style={{ marginLeft: "auto" }}>
            <SmallButton
              disabled={!isEditing}
              iconComponent={
                <PlusIcon
                  style={{
                    fill: isEditing ? Colors["gold-pmauto"] : "#d8d8d8"
                  }}
                />
              }
              label="Adicionar novo custo"
              onClick={() => showModal()}
            />
          </Row>
        </Row>
      </SectionTitle>
      <Column style={{ marginTop: 16 }}>
        {formikProps.values.costs && formikProps.values.costs.length > 0 ? (
          <CostsTable
            editCost={showEditCostModal}
            deleteCost={deleteCost}
            costs={formikProps.values.costs}
            isEditing={isEditing}
          />
        ) : (
          <StyledText>Não existem custos.</StyledText>
        )}
      </Column>
      <Row style={{ marginTop: 24, justifyContent: "flex-end" }}>
        <RatioContainer
          ratio={7 / 8}
          style={{ justifyContent: "flex-end", textAlign: "right" }}
        >
          <TotalText>Total:</TotalText>
        </RatioContainer>
        <RatioContainer ratio={1 / 8}>
          <ValueText>
            {euroFormatter.format(
              getCostsTotalValue(formikProps.values.costs || [])
            )}
          </ValueText>
        </RatioContainer>
        <RatioContainer ratio={1 / 8}></RatioContainer>
      </Row>
    </Section>
  );
};

const StyledText = styled("span")`
  align-self: center;
  ${Typography.H1_Regular_Center};
  font-size: 20px;
  margin-top: 24px;
`;

const TotalText = styled("span")`
  ${Typography["Body_Text_#1_High_Contrast_Right"]};
  margin-right: 8px;
`;

const ValueText = styled("span")`
  ${Typography["Body_Text_#1_Regular_Left"]};
`;
